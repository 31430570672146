export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true,
        is_admin: false,
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/',
        is_admin: false,
    },
    {
        id: 3,
        label: 'menuitems.animals.text',
        icon: 'fas fa-horse-head',
        link: '/animals',
        is_admin: true
    },
    {
        id: 4,
        label: 'menuitems.admin.text',
        icon: 'ri-group-fill',
        link: '/admin',
        is_admin: true
    },


    {
        id: 82,
        label: 'menuitems.lot.text',
        icon: 'fas fa-bookmark',
        link: '/lot',
        is_admin: true
    },
    {
        id: 83,
        label: 'menuitems.bill.text',
        icon: 'ri-barcode-box-line',
        link: '/bill',
        is_admin: true,
        subItems: [
            {
                id: 87,
                label: 'menuitems.bill.list',
                icon: 'fas fa-bookmark',
                link: '/bill'
            },
            {
                id: 86,
                label: 'menuitems.bill.report',
                icon: 'fas fa-bookmark',
                link: '/bill/reports'
            }
        ]
    },
    {
        id: 84,
        label: 'menuitems.user.text',
        icon: 'ri-user-2-fill',
        link: '/user',
        is_admin: true
    },
    {
        id: 85,
        label: 'menuitems.cashout.text',
        icon: 'ri-coins-line',
        link: '/cashout'
    },
]