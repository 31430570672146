export const menuSellerItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true,
        is_admin: false,
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/',
        is_admin: false,
    },
    
    {
        id: 3,
        label: 'menuitems.ecommerce.text',
        icon: 'ri-store-2-line',
        is_admin: false,
        subItems: [
            {
                id: 4,
                label: 'menuitems.ecommerce.list.products',
                link: '/ecommerce/products'
            },
            // {
            //     id: 5,
            //     label: 'menuitems.ecommerce.list.productdetail',
            //     link: '/ecommerce/product-detail'
            // },
            // {
            //     id: 6,
            //     label: 'menuitems.ecommerce.list.orders',
            //     link: '/ecommerce/orders'
            // },
            // {
            //     id: 7,
            //     label: 'menuitems.ecommerce.list.customers',
            //     link: '/ecommerce/customers'
            // },
            // {
            //     id: 8,
            //     label: 'menuitems.ecommerce.list.cart',
            //     link: '/ecommerce/cart'
            // },
            // {
            //     id: 9,
            //     label: 'menuitems.ecommerce.list.checkout',
            //     link: '/ecommerce/checkout'
            // },
            // {
            //     id: 10,
            //     label: 'menuitems.ecommerce.list.shops',
            //     link: '/ecommerce/shops'
            // },
            {
                id: 11,
                label: 'menuitems.ecommerce.list.addproduct',
                link: '/ecommerce/add-product'
            }
        ]
    },
]